import React from 'react'

function FourOhFourPage() {
	return (
		<div>
			<p>That page doesn't exist :(</p>
		</div>
	)
}

export default FourOhFourPage
